import {Box, IconButton, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import {useAuthStore, useUiComponentStore} from "../stateManagement";

const whiteList = [
    "6638a853d9d23851121027c5",
    "6638a88fd9d23851121027c7",
    "6639fe597e7fe26e0fb34c39",
    "663a042a7e7fe26e0fb34c3b",
    "663a04c97e7fe26e0fb34c3d",
    "663b48137fb4096dc9895889",
    "663b4b2a7fb4096dc989588e",
    "663d0bd9c89640ca4b0332ee",
    "6641e4097509098b515b8723",
    "6641e81e7509098b515b8725",
    "66424c759a0808419473cbd5",
    "664488dda0787069b6f6cf19",
    "665068c195823d8fa9339509",
    "667be686468d6c78a26f093a",
    "667bea79468d6c78a26f093c",
    "6683c1fc468d6c78a26f093f",
    "66964173b48f9bfc089ef204",
    "6696422bb48f9bfc089ef208",
    "66964da3b48f9bfc089ef20e",
    "6698d2d3b48f9bfc089ef211",
    "6698e193b48f9bfc089ef215",
    "6698e819b48f9bfc089ef21a",
    "6698e81ab48f9bfc089ef21c",
    "66acaa81b48f9bfc089ef240",
    "66b07f7eb48f9bfc089ef266",
    "66d6bc06b48f9bfc089ef26c",
    "66d6be78b48f9bfc089ef26e",
    "66d6c13a9d7327f7d200c105",
    "66eaa5cff4d7de83f39ad2fd",
    "66eaae07f4d7de83f39ad2ff",
    "66ffbfd4f4d7de83f39ad301",
    "671624a9ef68a4b689c7ab1c",
    "672358f5ef68a4b689c7ab1e",
    "672358fbef68a4b689c7ab20",
    "6723594def68a4b689c7ab25",
    "6729ff88ef68a4b689c7ab27",
    "672a02e9ef68a4b689c7ab2a",
    "6735d1afef68a4b689c7ab2c",
    "675c1915c208393439c1fa73"
]

export default function AppBarContent() {
    const {setOpen} = useUiComponentStore()
    const user = useAuthStore(state => state.user)

    return (
        <>
            <Box sx={{height: 40}}>
                {whiteList.includes(user?._id) && <div>
                    <img src={'/fiba-meme_out.gif'} alt={'sticazzi'} style={{ height: 40, width: 40 }} />
                </div>}
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, mr: 2 }}>
                App ordinazioni Passage
            </Typography>
            <IconButton onClick={e => {
                e.stopPropagation()
                setOpen(open => !open)
                // commented out because the appbar already does it and stopPropagation isn't working
            }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                // sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
        </>
    )
}
